export default {
  headers: [
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/sestrunj/logo.jpg',
        url: 'https://ahernrealestategroup.com/',
      },
      breakpoint: 999999,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-sestrunj-header-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-sestrunj-header-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-sestrunj-header',
        headerSectionsClassName: 'on-sestrunj-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-sestrunj-header-menu',
        itemClassName: 'on-sestrunj-header-item',
        menuClassName: 'on-sestrunj-header-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-sestrunj-header-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'sestrunj-team-item',
            content: 'Team',
            menuItems: [
              {
                id: 'sestrunj-team-item-about',
                content: 'Meet Jon & Camille',
                url: 'https://ahernrealestategroup.com/about',
              },
              {
                id: 'sestrunj-team-item-team',
                content: 'Meet The Team',
                url: 'https://ahernrealestategroup.com/team',
              },
            ],
          },
          {
            id: 'sestrunj-testimonials-item',
            content: 'Testimonials',
            url: 'https://ahernrealestategroup.com/testimonials',
          },
          {
            id: 'sestrunj-properties-item',
            content: 'Properties',
            menuItems: [
              {
                id: 'sestrunj-properties-item-search',
                content: 'Search a Property',
                url: 'https://ahernrealestategroup.com/home-search/listings',
              },
              {
                id: 'sestrunj-properties-item-active',
                content: 'Active Listings',
                url: 'https://ahernrealestategroup.com/properties/sale',
              },
              {
                id: 'sestrunj-properties-item-sold',
                content: 'Sold Properties',
                url: 'https://ahernrealestategroup.com/properties/sold',
              },
            ],
          },
          {
            id: 'sestrunj-communities-item',
            content: 'Communities',
            url: 'https://ahernrealestategroup.com/neighborhoods',
          },
          {
            id: 'sestrunj-home-valuation-item',
            content: 'Home Valuation',
            url: 'https://ahernrealestategroup.com/home-valuation',
          },
          {
            id: 'sestrunj-trade-in-item',
            content: 'Exclusive Trade-In Program',
            url: 'https://trade-in.ahernrealestategroup.com/',
          },
          {
            id: 'sestrunj-contact-us-item',
            content: 'Contact',
            url: 'mailto:Camille@AhernREGroup.com',
          },
        ],
        items: [
          {
            id: 'sestrunj-menu-title',
            content: 'Ahern Real Estate Group',
          },
          {
            id: 'sestrunj-menu-items',
            menuItems: [
              {
                id: 'sestrunj-menu-items-home',
                content: 'Home',
                url: 'https://ahernrealestategroup.com/',
              },
              {
                id: 'sestrunj-menu-items-about',
                content: 'About',
                url: 'https://ahernrealestategroup.com/about',
              },
              {
                id: 'sestrunj-menu-items-featured',
                content: 'Featured Listings',
                url: 'https://ahernrealestategroup.com/properties/sale',
              },
              {
                id: 'sestrunj-menu-items-sold',
                content: 'Sold Listings',
                url: 'https://ahernrealestategroup.com/properties/sold',
              },
              {
                id: 'sestrunj-menu-items-search',
                content: 'Property Search',
                url: 'https://ahernrealestategroup.com/home-search/listings',
              },
              {
                id: 'sestrunj-menu-items-communities',
                content: 'Communities',
                url: 'https://ahernrealestategroup.com/neighborhoods',
              },
              {
                id: 'sestrunj-menu-items-testimonials',
                content: 'Testimonials',
                url: 'https://ahernrealestategroup.com/testimonials',
              },
              {
                id: 'sestrunj-menu-items-trade-in',
                content: 'Exclusive Trade-In Program',
                url: 'https://trade-in.ahernrealestategroup.com',
              },
              {
                id: 'sestrunj-contact-us-item',
                content: 'Contact Us',
                url: 'mailto:Camille@AhernREGroup.com',
              },
            ],
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-sestrunj-footer-one',
      className: 'on-sestrunj-footer on-sestrunj-footer-one',
      sectionClassName: 'on-sestrunj-footer-container footer-container-one',
      columns: [
        {
          id: 'on-sestrunj-footer-one',
          className: 'col-lg-7 first-col',
          items: [
            {
              id: 'f-1-col-1-item-1',
              type: 'heading',
              elementType: 'h1',
              data: 'Ahern Real Estate Group',
              className: 'col-1',
            },
          ],
        },
      ],
    },
    {
      id: 'on-sestrunj-footer-two',
      className: 'on-sestrunj-footer on-sestrunj-footer-two',
      sectionClassName: 'on-sestrunj-footer-container footer-container-two',
      columns: [
        {
          id: 'on-sestrunj-footer-two',
          className: 'on-sestrunj-footer-two-column map-column',
          items: [
            {
              id: 'f-2-col-1-item-socials-1',
              className: 'on-sestrunj-footer-socials',
              itemClassName: 'on-sestrunj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'sestrunj-map',
                  iconTypeClassName: 'fa',
                  iconClassName: 'map-marked-alt',
                  url: '',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-2',
              type: 'heading',
              elementType: 'h2',
              data: 'ADDRESS',
              className: 'col-item',
            },
            {
              id: 'f-2-col-1-item-3',
              type: 'heading',
              elementType: 'p',
              data: '671 SAN RAMON VALLEY BLVD , DANVILLE, CA 94526',
              className: 'col-item col-address',
            },
          ],
        },
        {
          id: 'on-sestrunj-footer-two',
          className: 'on-sestrunj-footer-two-column phone-column',
          items: [
            {
              id: 'f-2-col-1-item-socials-2',
              className: 'on-sestrunj-footer-socials',
              itemClassName: 'on-sestrunj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'sestrunj-phone',
                  iconTypeClassName: 'fa',
                  iconClassName: 'phone',
                  url: '',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-4',
              type: 'heading',
              elementType: 'h2',
              data: 'PHONE NUMBER',
              className: 'col-item',
            },
            {
              id: 'f-2-col-1-item-5',
              type: 'heading',
              elementType: 'p',
              data: '(925) 963-0068',
              className: 'col-item col-item-line',
              url: 'tel:(925) 963-0068',
            },
          ],
        },
        {
          id: 'on-sestrunj-footer-two',
          className: 'on-sestrunj-footer-two-column',
          items: [
            {
              id: 'f-2-col-1-item-socials-3',
              className: 'on-sestrunj-footer-socials',
              itemClassName: 'on-sestrunj-footer-socials-item',
              type: 'socialIcons',
              items: [
                {
                  id: 'sestrunj-mail',
                  iconTypeClassName: 'fa',
                  iconClassName: 'envelope',
                  url: '',
                },
              ],
            },
            {
              id: 'f-2-col-1-item-6',
              type: 'heading',
              elementType: 'h2',
              data: 'EMAIL',
              className: 'col-item',
            },
            {
              id: 'f-2-col-1-item-7',
              type: 'heading',
              elementType: 'h2',
              data: 'CAMILLE@AHERNREGROUP.COM',
              className: 'col-item col-item-line',
              url: 'mailto:CAMILLE@AHERNREGROUP.COM',
            },
          ],
        },
      ],
    },
    {
      id: 'on-sestrunj-footer-three',
      className: 'on-sestrunj-footer on-sestrunj-footer-three',
      sectionClassName: 'on-sestrunj-footer-container footer-container-three',
      columns: [
        {
          id: 'on-sestrunj-footer-three',
          className: 'first-col',
          items: [
            {
              id: 'f-3-col-1-item-1',
              type: 'image',
              className: 'on-sestrunj-footer-logo',
              style: { width: '210px' },
              url: 'https://si-homelight.s3.amazonaws.com/sites/sestrunj/footer-logo-coldwell.webp',
            },
          ],
        },
        {
          id: 'on-sestrunj-footer-three',
          className: 'second-col',
          items: [
            {
              id: 'f-3-col-1-item-3',
              type: 'itemContent',
              className: 'pb-2',
              items: [
                {
                  content:
                    'The property information herein is derived from various sources that may include, but not be limited to, county records and the Multiple Listing Service, and it may include approximations. Although the information is believed to be accurate, it is not warranted and you should not rely upon it without personal verification. ©2020 Coldwell Banker. All Rights Reserved. Coldwell Banker and the Coldwell Banker logos are trademarks of Coldwell Banker Real Estate LLC. The Coldwell Banker® System is comprised of company owned offices which are owned by a subsidiary of Realogy Brokerage Group LLC and franchised offices which are independently owned and operated. The Coldwell Banker System fully supports the principles of the Fair Housing Act and the Equal Opportunity Act.',
                },
                {
                  id: 'f-3-col-1-item-4',
                  type: 'image',
                  className: 'on-sestrunj-footer-text-logo',
                  style: { width: '40px', paddingLeft: '10px' },
                  url: '',
                  imageUrl: 'https://si-homelight.s3.amazonaws.com/sites/sestrunj/logo-footer-realtor-equal.png',
                },
              ],
            },
            {
              id: 'f-3-col-1-item-5',
              type: 'heading',
              elementType: 'p',
              data: 'CA DRE# 01865464',
              className: '',
            },
          ],
        },
      ],
    },
    {
      id: 'on-sestrunj-footer-four',
      className: 'on-sestrunj-footer on-sestrunj-footer-four',
      sectionClassName: 'on-sestrunj-footer-container footer-container-four',
      columns: [
        {
          id: 'on-sestrunj-footer-four-col',
          className: 'col-lg-6 last-first-col',
          items: [
            {
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 pt-md-0',
              style: {},
              items: [
                {
                  content: 'Copyright © 2022  ',
                },
                {
                  content: ' | ',
                },
                {
                  content: 'Policy',
                  url: 'https://ahernrealestategroup.com/terms-and-conditions',
                  className: 'on-privacy-link',
                },
              ],
            },
          ],
        },
        {
          id: 'on-sestrunj-footer-four-col',
          className: 'col-lg-6 last-first-col',
          items: [
            {
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 pt-md-0 on-sestrunj-powered',
              style: {},
              items: [
                {
                  content: 'Powered by ',
                },
                {
                  content: 'Luxury Presence',
                  url: 'https://www.luxurypresence.com/',
                  className: 'on-luxury-link',
                },
              ],
            },
          ],
        },
      ],
    },
  ],

  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
    'tradeIn.howItWorks.className': 'howItWorksClassName',
    'cashOffer.howItWorks.className': 'howItWorksClassName',
  },
  siteData: {
    agency: 'Ahern Real Estate Group',
    agentName: 'Jon & Camilla Ahern',
    agentDescription: 'Top agents in Northern California',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/sestrunj/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/sestrunj/hero-bg.jpg',
  },
};
